<script setup>
const props = defineProps(['content']);

const tags = ref({
  unordered: 'ul',
  ordered: 'ol',
});
</script>

<template>
  <div>
    <component :is="tags[content.style]" class="list">
      <li v-for="li in content.items" class="list__item" v-html="li"></li>
    </component>
  </div>
</template>
