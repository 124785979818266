<script setup>
const props = defineProps(['content']);

const grid = resolveComponent('DynamicGridGallery');
const swipe = resolveComponent('DynamicSwipeGallery');

const componentMap = {
  grid: grid,
  swipe: swipe,
};

const type = computed(() => componentMap[props.content.type]);
</script>

<template>
  <component :is="type" :content="content" />
</template>
