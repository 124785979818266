<!-- eslint-disable vue/require-component-is -->
<script setup>
const elementsRefs = ref([]);
const elementsVisibility = ref([]);
let observer = false;

onMounted(() => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const index = elementsRefs.value.indexOf(entry.target);
        if (index !== -1 && entry.isIntersecting) {
          elementsVisibility.value[index] = true;
        }
      });
    },
    { threshold: 0.1 }
  );

  elementsRefs.value.forEach((el) => {
    if (el) observer.observe(el);
  });
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});

const header = resolveComponent('DynamicHeader');
const paragraph = resolveComponent('DynamicText');
const perex = resolveComponent('DynamicPerex');
const image = resolveComponent('DynamicImage');
const embed = resolveComponent('DynamicEmbed');
const list = resolveComponent('DynamicList');
const table = resolveComponent('DynamicTable');
const gallery = resolveComponent('DynamicGallery');
const raw = resolveComponent('DynamicRaw');

const componentMap = {
  header: header,
  paragraph: paragraph,
  perex: perex,
  image: image,
  embed: embed,
  list: list,
  table: table,
  gallery: gallery,
  raw: raw,
};

const props = defineProps({
  content: {
    type: [Object, Array, String],
    required: true,
  },
});

//const parsedContent = JSON.parse(props.content);

let parsedContent;

try {
  parsedContent = JSON.parse(props.content);
} catch (e) {
  parsedContent = props.content;
}

const getComponentName = (type) => {
  return type in componentMap ? componentMap[type] : null;
};
</script>

<template>
  <div class="page-content">
    <div v-for="(item, index) in parsedContent" :key="index" ref="elementsRefs">
      <component
        :is="getComponentName(item.type)"
        :content="item.data"
        class="page-content__item visible"
        :class="[
          `editor-${item.type}`,
          {
            'visible animate__animated animate__fadeInUp': elementsVisibility[index],
            invisible: !elementsVisibility[index],
          },
        ]"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-content {
  &__item {
    margin-top: 30px;
    // &:first-child {
    //   margin-top: 0;
    // }
  }

  .animate__animated {
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
  }
}
</style>
