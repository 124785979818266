<script setup>
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';

const props = defineProps(['gallerySelector', 'items']);
const store = useCarouselStore();

const { setActiveItem, setGalleryItems } = store;

const carOptions = { autoWidth: true, focus: 0, omitEnd: true };

//vars for counting the active images in view
const lastVisible = ref(1);
const visibleSelection = ref(0);
const imageInView = ref(null);
const imageInViewWidth = ref(null);
const intervalId = ref(null);

const activeIndex = ref(0);
//const items = ref(props.content.items);
const getSelector = (selector) => `.s${props.gallerySelector} ${selector}`;

const footer = ref(null);

const calculateActiveIndex = () => {
  const gallery = ref(document.querySelector(getSelector('.splide__list')));
  const children = ref(gallery.value?.children);
  for (let i = 0; i < children.value?.length; i++) {
    if (children.value[i]?.classList.contains('is-active')) {
      activeIndex.value = i;
      return activeIndex.value;
    }
  }
};

const calculateImagesInView = () => {
  const gallery = ref(document.querySelector(getSelector('.splide__track')));

  if (gallery && gallery.value != null) {
    const galleryWidth = ref(gallery.value.offsetWidth);

    //get width of active image and store it in visible selection
    imageInView.value = document.querySelector(getSelector('.splide__slide.is-active'));
    imageInViewWidth.value = imageInView?.value?.offsetWidth;
    visibleSelection.value = imageInViewWidth.value;
    lastVisible.value = 1;

    //while gallery is wider then first image:
    while (galleryWidth.value > visibleSelection.value) {
      imageInView.value = imageInView?.value?.nextElementSibling;
      imageInViewWidth.value = imageInView?.value?.offsetWidth;
      visibleSelection.value += imageInViewWidth.value;
      lastVisible.value++;
    }
  } else return;
};

const setPictureOverlay = (index) => {
  setActiveItem(index);
  setGalleryItems(props.items);
  if (index == null) {
    footer.value != null && footer.value.classList.remove('z--hide');
  } else {
    footer.value != null && footer.value.classList.add('z--hide');
  }
};

onMounted(() => {
  footer.value = document.querySelector('.footer');
  intervalId.value = setInterval(() => {
    calculateActiveIndex();
    calculateImagesInView();
  }, 1000);
});

onUnmounted(() => {
  clearInterval(intervalId.value);
  setActiveItem(null);
  setGalleryItems([]);
});

const getVideoCode = (url) => {
  if (url) {
    const parts = url.split('v=');
    return parts[1]?.slice(0, 11);
  }
};
</script>

<template>
  <Splide :has-track="false" aria-label="My Favorite Images" :options="carOptions">
    <SplideTrack>
      <SplideSlide v-for="(item, index) in items" :key="index">
        <img
          v-if="item.type === 'embed'"
          :id="'video_' + getVideoCode(item.source)"
          :src="'https://img.youtube.com/vi/' + getVideoCode(item.source) + '/0.jpg'"
          alt="Youtube thumb"
          @click="setPictureOverlay(index)"
          @keyup.enter="setPictureOverlay(index)"
        />
        <img
          v-else
          :src="useGetImageUrl(item.url, null, 350)"
          style="max-height: 400px"
          :alt="item.caption || 'image without caption'"
          @click="setPictureOverlay(index)"
          @keyup.enter="setPictureOverlay(index)"
        />
      </SplideSlide>
    </SplideTrack>

    <div class="display-flex">
      <p>{{ `${activeIndex + 1}—${activeIndex + lastVisible < props.items.length ? activeIndex + lastVisible : props.items.length} / ${props.items.length}` }}</p>

      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev gallery__arrow gallery__arrow--pre" @click="calculateImagesInView">
          <i class="icon-arrow-left" />
        </button>
        <button class="splide__arrow splide__arrow--next gallery__arrow gallery__arrow--next" @click="calculateImagesInView">
          <i class="icon-arrow-right" />
        </button>
      </div>
    </div>
  </Splide>
</template>

<style lang="css">
@import '@splidejs/vue-splide/css';

.splide__slide {
  margin-right: 15px;
  cursor: pointer;
}

.splide__arrow {
  position: static;
  transform: none;
  background-color: transparent;
  font-size: 16px;
}

.splide__arrows {
  display: flex;
  justify-content: flex-end;
}

.splide__pagination {
  display: none;
}
.display-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
