<script setup>
const props = defineProps(['content']);
const tags = ref(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);
</script>

<template>
  <div>
    <component :is="tags[content.level - 1]" class="dynamicHeading" lass="'heading--' + content.level" :id="useSlugify(content.text)">
      <span :class="'heading--' + content.level" v-html="content.text"></span>
    </component>
  </div>
</template>

<style lang="scss">
.dynamicHeading {
  a {
    cursor: pointer;
    line-height: 1.5rem;
    @include underline-transition-reverse(1px);
  }
}
</style>
