import { defineStore } from 'pinia';

export const useCarouselStore = defineStore('carouselStore', {
  state: () => ({
    activeItem: null,
    galleryItems: [],
  }),
  actions: {
    setActiveItem(value) {
      this.activeItem = value;
    },
    setGalleryItems(value) {
      this.galleryItems = value;
    },
  },
});
