<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps(['content']);
const tags = ref([]);

onMounted(() => {
  const temp = document.createElement('div');
  temp.innerHTML = props.content.html;

  const all = temp.getElementsByTagName('*');
  const uniqueTags = [];

  for (let i = 0, max = all.length; i < max; i++) {
    if (!uniqueTags.some((tag) => tag.name === all[i].tagName)) {
      const tag = {
        name: all[i].tagName,
        height: all[i].height,
        width: all[i].width,
        string: all[i].outerHTML,
      };

      uniqueTags.push(tag);
    }
  }

  tags.value = uniqueTags;
});
</script>

<template>
  <div>
    <div v-for="tag in tags">
      <div :class="{ scale: tag.name == 'IFRAME' }" :style="'padding-bottom: ' + (tag.height / tag.width) * 100 + '%;'" v-html="tag.string" />
    </div>
  </div>
</template>
