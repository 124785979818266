<script setup>
const props = defineProps(['content']);

const chtmlCheck = (string) => {
  return string.match(/<\/?[a-z][\s\S]*>/);
};
</script>

<template>
  <div v-if="content.text">
    <div v-if="chtmlCheck(content.text)" v-html="content.text"></div>
    <div v-else v-html="content.text"></div>
  </div>
</template>
