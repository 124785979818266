<script setup>
const props = defineProps(['content']);
const selector = props.content.items[0].id;
</script>

<template>
  <div :class="`s${selector}`">
    <PictureSwipe :items="content.items" :gallerySelector="selector" />
    <Overlay />
  </div>
</template>
