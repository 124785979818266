<script setup>
const props = defineProps(['content']);
const store = useCarouselStore();

const { setActiveItem, setGalleryItems } = store;

const columns = ref(3);
const footer = ref(null);
const active = ref(false);

const setPictureOverlay = (index) => {
  active.value = true;
  setActiveItem(index);
  setGalleryItems(props.content.items);
  if (index == null) {
    footer.value != null && footer.value.classList.remove('z--hide');
  } else {
    footer.value != null && footer.value.classList.add('z--hide');
  }
};

onMounted(() => {
  footer.value = document.querySelector('.footer');
});

onUnmounted(() => {
  setActiveItem(null);
  setGalleryItems([]);
});

const youtubeParser = (url) => {
  var regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  return match && match[2].length == 11 ? match[2] : null;
};
</script>

<template>
  <div class="grid">
    <div v-for="columnIndex in columns" :key="columnIndex" class="grid--column">
      <div v-for="(item, index) in content.items" :key="index">
        <div v-if="(index + 1 - columnIndex) % columns === 0">
          <img
            class="grid--image"
            :src="useGetImageUrl(item.thumbnail, 500) || useGetImageUrl(item.url, 600) || 'http://i3.ytimg.com/vi/' + youtubeParser(item.source) + '/sddefault.jpg'"
            :alt="item.caption && item.caption"
            @click="setPictureOverlay(index)"
            @keyup.enter="setPictureOverlay(index)"
          />
        </div>
      </div>
    </div>
  </div>
  <Overlay />
</template>

<style lang="scss" scoped>
.gallery {
  &__items {
    position: relative;
    margin-left: -$main-padding-mobile-x;
    margin-right: -$main-padding-mobile-x;

    @include mq($md) {
      margin-left: 0;
      margin-right: -$main-padding-x;
    }
  }

  .swiper-slide {
    width: auto;
    height: 350px;

    &--auto {
      height: auto;
    }
  }

  &__image {
    display: block;
    height: 100%;
  }

  &__bar {
    display: flex;
    width: 100%;
    padding-top: 5px;
  }

  &__arrows {
    margin-left: auto;
  }

  &__arrow {
    @include button-reset;
    cursor: pointer;
    height: 32px;
    text-align: center;
    transition: opacity 300ms;
    width: 32px;

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      opacity: 0.2;
    }
  }

  &--swipe {
    overflow: hidden;
  }

  &--grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    //max-width: $content-max-width;

    figure {
      padding: 10px;
      width: 100%;

      @include mq($sm) {
        width: 50%;
      }

      @include mq($md) {
        width: 33.333%;
      }

      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.wrapper {
  &.has-warning {
    .pswp__top-bar {
      top: 60px;
    }
  }
}
.pswp {
  height: 100vh;
  visibility: visible;
}

.editor-gallery {
  position: unset;
  z-index: auto;
}

.pswp__img {
  width: auto !important;
  z-index: auto;

  // left: 50% !important;
  // transform: translate(-50%, 0) !important;
}

.pswp__button {
  i {
    pointer-events: none;
  }
}
.pswp__ui--idle {
  .pswp__button {
    opacity: 1;
  }
}

.pswp__zoom-wrap {
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.pswp-video {
  line-height: 0;
  width: 100%;
  max-width: 900px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: auto;

  &__center {
    background: pink;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
  }

  &__iframe {
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.grid {
  display: flex;
  width: calc(100% + 16px);
  margin: 0 -8px;
  @media (max-width: 600px) {
    display: block;
  }
}

.grid--column {
  margin: 8px;
  flex: 1;
  flex-basis: 0;
  min-width: 0;
}
.grid--image {
  margin-bottom: 16px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
